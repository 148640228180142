import React from 'react';

export default function MCS({ cycle, pdf, register, setValue, submitForm, subscriptionActive }) {
  const registerMCS = register('mcs', {
    disabled: !subscriptionActive,
    onBlur: () => submitForm(),
    onChange: (event) => {
      const [value] = event.target.value.match(/(\d)(\d)?(\.)?(\d)?/) || [''];

      setValue('mcs', value);
    },
  })

  return (
    <td className="mcs-entry p-1 d-flex flex-column justify-content-center border-0">
      <div className="form-group mb-0">
        <label className="w-100 text-center mb-1">
          MCS
        </label>
        { pdf
          ? <div className="text-center">{cycle.mcs}</div>
          : <input className="w-100 text-center" {...registerMCS} />
        }
      </div>
    </td>
  );
}
