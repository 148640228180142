import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';
import {
  captureClick,
  onSubmit,
} from '../../ChartUtilities';

export default function ExtraCell({
  abdominalPains,
  form,
  medications,
  pmss,
  pushMedication,
  pushPms,
  submitForm
}) {
  const showCode = (code) => {
    return fupActive || !code.fup_only;
  }

  const sortCode = (left, right) => {
    return left.sort_order - right.sort_order;
  }

  const abdominalPainId = form.watch('abdominal_pain_id');
  const medicationIds = form.watch('medication_ids');
  const pmsIds = form.watch('pms_ids');

  const [dropdown, setDropdown] = useState(null);
  const togglePms = () => dropdown === 'pms' ? setDropdown(null) : setDropdown('pms');
  const toggleMedication = () => dropdown === 'medication' ? setDropdown(null) : setDropdown('medication');

  const pmsDropdownRef = useRef(null);
  const medicationDropdownRef = useRef(null);
  useOnClickOutside(pmsDropdownRef, () => {
    if (dropdown !== 'medication') setDropdown(null);
  });
  useOnClickOutside(medicationDropdownRef, () => {
    if (dropdown !== 'pms') setDropdown(null);
  });

  const toggleCode = (field, value) => {
    if (form.getValues(field) === value) {
      form.setValue(field, null);
    } else {
      form.setValue(field, value);
    }
  }

  // plural ids
  const toggleCodes = (field, value) => {
    const currentIds = form.getValues(field);

    if (currentIds.includes(value)) {
      const updatedIds = currentIds.filter((id) => id !== value);

      form.setValue(field, updatedIds);
    } else {
      form.setValue(field, [...currentIds, value]);
    }
  }

  const [addMedication, setAddMedication] = useState(false);
  const onAddMedication = () => {
    setAddMedication(true);
    setDropdown(null);
  }

  const medicationForm = useForm({});
  const createMedication = async () => {
    const create = async (medication) => await onSubmit('/app/medications', { medication }, 'POST');
    const attributes = medicationForm.getValues();
    const { data: medication }= await create(attributes);

    pushMedication(medication);
    toggleCodes('medication_ids', medication.id);
    setAddMedication(false);
    medicationForm.reset();
  };

  const [addPms, setAddPms] = useState(false);
  const onAddPms = () => {
    setAddPms(true);
    setDropdown(null);
  }

  const hideMedication = async ({ id }) => {
    const update = async (medication) => await onSubmit(`/app/medications/${id}`, { medication }, 'PUT');
    const { data: medication } = await update({ visible: false });

    pushMedication(medication);
  };

  const pmsForm = useForm({});
  const createPms = async () => {
    const create = async (pms) => await onSubmit('/app/pmss', { pms }, 'POST');
    const attributes = pmsForm.getValues();
    const { data: pms }= await create(attributes);

    pushPms(pms);
    toggleCodes('pms_ids', pms.id);
    setAddPms(false);
    pmsForm.reset();
  };

  const hidePms = async ({ id }) => {
    const update = async (pms) => await onSubmit(`/app/pmss/${id}`, { pms }, 'PUT');
    const { data: pms } = await update({ visible: false });

    pushPms(pms);
  };

  return (
    <div className="extra-entry shadow border mt-2 rounded">
      <form
        onClick={captureClick}
        onSubmit={submitForm}
      >
        <div className="row py-2 px-3 code-selector">
          <div className="mt-3 col-12">
            <div className="dropdown">
              <a className="btn btn-grey dropdown-toggle" role="button" onClick={togglePms}>
                PMS Symptoms
              </a>

              <div className={`dropdown-menu ${dropdown === 'pms' ? 'show' : ''}`} ref={pmsDropdownRef}>
                {pmss.filter(({ id, visible }) => visible || pmsIds.includes(id)).toSorted(sortCode).map((pms) =>
                  <div key={pms.id} className="d-flex">
                    <a
                      className="dropdown-item"
                      onClick={() => toggleCodes('pms_ids', pms.id)}
                    >
                      <i className={`mr-2 fa fa-check ${!pmsIds.includes(pms.id) ? 'invisible' : ''}`} />
                      { pms.name }
                    </a>
                    { pms.user_id && !pmsIds.includes(pms.id)
                      ? <a className="align-self-center border font-sm mr-2 p-1 rounded-circle" onClick={() => hidePms(pms) }>X</a>
                      : null
                    }
                  </div>
                )}
                <a className="dropdown-item" onClick={onAddPms}>+ Add Symptom</a>
              </div>
            </div>
            {
              pmsIds.length > 0 ? (
                <div className="mt-2">
                  <ul>
                    {pmss.toSorted(sortCode).map((pms) => (
                      pmsIds.includes(pms.id) ? <li key={pms.id}>{pms.name}</li> : null
                    ))}
                  </ul>
                </div>
              ) : null
            }
            {
              addPms
              ? (
                <div className="form-group mt-2 d-flex">
                  <input className="form-control mr-1" type="text" {...pmsForm.register('name')} />
                  <button className="btn btn-grey" onClick={createPms}>Add</button>
                </div>
              )
              : null
            }
          </div>

          <div className="mt-2 col-12">
            <div>
              <div className="dropdown">
                <a className="btn btn-grey dropdown-toggle" role="button" onClick={toggleMedication}>
                  Meds/Procedures
                </a>

                <div className={`dropdown-menu ${dropdown === 'medication' ? 'show' : ''}`} ref={medicationDropdownRef}>
                  {medications.filter(({ id, visible }) => visible || medicationIds.includes(id)).toSorted(sortCode).map((medication) =>
                    <div key={medication.id} className="d-flex">
                      <a
                        className="dropdown-item"
                        onClick={() => toggleCodes('medication_ids', medication.id)}
                      >
                        <i className={`mr-2 fa fa-check ${!medicationIds.includes(medication.id) ? 'invisible' : ''}`} />
                        { medication.name }
                      </a>
                      { medication.user_id && !medicationIds.includes(medication.id)
                        ? <a className="align-self-center border font-sm mr-2 p-1 rounded-circle" onClick={() => hideMedication(medication) }>X</a>
                        : null
                      }
                    </div>
                  )}
                  <a className="dropdown-item" onClick={onAddMedication}>+ Add Med/Procedure</a>
                </div>
              </div>
            </div>
            {
              medicationIds.length > 0 ? (
                <div className="mt-2">
                  <ul>
                    {medications.toSorted(sortCode).map((medication) => (
                      medicationIds.includes(medication.id) ? <li key={medication.id}>{medication.name}</li> : null
                    ))}
                  </ul>
                </div>
              ) : null
            }
            {
              addMedication
              ? (
                <div className="form-group mt-2 d-flex">
                  <input className="form-control mr-1" type="text" {...medicationForm.register('name')} />
                  <button className="btn btn-grey" onClick={createMedication}>Add</button>
                </div>
              )
              : null
            }
          </div>

          <div className="col-12 d-flex flex-column">
            <label className="mt-3">Abdominal Pain</label>
            <div>
              {abdominalPains.filter(showCode).toSorted(sortCode).map((abdominalPain) => (
                <label
                  key={abdominalPain.id}
                  className={`btn btn-grey mr-1 ${abdominalPain.id == abdominalPainId && 'active'}`}
                  onClick={() => toggleCode('abdominal_pain_id', abdominalPain.id)}
                >
                  <span>{abdominalPain.abbreviation}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="vdrs-step mt-2 col-12">
            <div className="input text optional">
              <label className="text optional">Notes</label>
              <textarea className="text optional form-control" maxLength={150} {...form.register('notes')} />
            </div>
          </div>
          <div className="vdrs-step mt-3 col-12 d-flex">
            <label className="continue mt-1">
              <button className="btn" onClick={submitForm}>Submit</button>
            </label>
          </div>
        </div>
      </form>
    </div>
  );
}
