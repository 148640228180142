import React, { useState } from 'react';
import {
  captureClick,
  find_record,
} from '../../ChartUtilities';

function filterStickers(stickers, keys) {
  // all stamps
  if (keys.some((key) => ["k2", "k3", "k4", "k5"].includes(key))) return stickers;

  // no white k stamps
  if (keys.includes('k1')) {
    return stickers.filter(({ sticker_text }) => sticker_text !== 'K' || sticker_text !== null);
  }

  // no yellow or white k stamps
  return stickers.filter(({ vdrs_row }) => vdrs_row !== 3);
}

export default function StampEntry({
  correctedDay,
  day,
  form,
  fupActive,
  keys,
  stickers,
  submitForm,
}) {
  const selectStamp = (stickerId) => {
    form.setValue('correction', fupActive)
    form.setValue('sticker_id', stickerId);
    // submitForm();
  };

  const clearStamp = () => selectStamp(null);
  const clearCorrection = () => selectStamp(correctedDay.sticker_id);

  const filteredStickers = filterStickers(stickers, keys);
  const stickerId = form.watch('sticker_id');

  const isSecondaryStamp = (sticker) => {
    const currentSticker = find_record(stickers, stickerId);

    if (!sticker.sticker_text) return false;
    if (sticker.hex_color !== currentSticker.hex_color) return false;
    if (sticker.visible_baby !== currentSticker.visible_baby) return false;
    return true;
  }

  return (
    <div className="stamp-entry shadow border mt-2 rounded">
      <form onClick={captureClick}>
        <div className="vdrs-step vdrs-stickers col">
          {/* simple stamps */}
          <div className="d-flex justify-content-center">
            {filteredStickers.filter((sticker) => !sticker.sticker_text).map((sticker) => (
              <div
                key={sticker.id}
                className="d-flex"
              >
                <div
                  className={`stamp-input m-2 ${sticker.visible_baby ? 'show-baby' : ''}`}
                  style={{ backgroundColor: sticker.hex_color }}
                  onClick={() => selectStamp(sticker.id)}
                >
                  {sticker.sticker_text}
                </div>
              </div>
            ))}
          </div>
          {/* secondary stamps */}
          {(day.sticker_id)
            ? (
              <div className="d-flex justify-content-center">
                {filteredStickers.filter(isSecondaryStamp).map((sticker) => (
                  <div
                    key={sticker.id}
                    className={`d-flex ${sticker.sticker_text == 'K' ? 'k-stamp' :  ''}`}
                  >
                    <div
                      className="stamp-text-select m-2"
                      onClick={() => selectStamp(sticker.id)}
                    >
                      {sticker.sticker_text}
                    </div>
                  </div>
                ))}
              </div>
              )
            : null
          }
          {/* controls */}
          {day.sticker_id
            ? (
              <div className="d-flex justify-content-center my-2 text-center">
                { !fupActive && day.sticker.id
                  ? <a className="text-primary p-1" onClick={clearStamp}>Clear Stamp</a>
                  : null
                }
                {fupActive && day.sticker_id != correctedDay.sticker_id
                  ? <a className="text-primary p-1" onClick={clearCorrection}>Clear Correction</a>
                  : null
                }
                <a className="text-primary p-1" onClick={() => submitForm()}>Cancel</a>
                <a className="text-primary p-1" onClick={() => submitForm()}>Submit</a>
              </div>
            ) : null
          }
        </div>
      </form>
    </div>
  );
}