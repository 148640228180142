import React, { useState } from 'react';
import { Prescription } from 'react-bootstrap-icons';
import { useOnClickOutside } from 'react-hanger';
import {
  checkSession,
  isChartable,
  isDirty,
  onSubmit,
} from '../../ChartUtilities';
import ExtraEntry from './ExtraEntry';

export default function ExtraCell({
  abdominalPains,
  addFup,
  day,
  form,
  medications,
  pmss,
  pushDay,
  pushMedication,
  pushPms,
  setAddFup,
}) {
  const [showExtraEntry, setShowExtraEntry] = useState(false);

  const submitForm = async () => {
    if (isDirty(form, day)) {
      const update = async (chart_day) => await onSubmit(`/app/chart_days/${chart_day.id}`, { chart_day });
      // TODO: this should be the correct call
      // await form.handleSubmit(update);
      const chartDay = form.getValues();
      const { data: updatedDay } = await update(chartDay);

      pushDay(day.id, updatedDay);
    }

    setShowExtraEntry(false);
  }

  const abdominalPainId = form.watch('abdominal_pain_id');
  const medicationIds = form.watch('medication_ids');
  const pmsIds = form.watch('pms_ids');
  const notes = form.watch('notes');

  const abdominalPain = abdominalPains.find(({ id }) => id === abdominalPainId);

  const onClick = async () => {
    checkSession();

    if (!subscriptionActive) return;

    if (addFup) {
      const fup = form.getValues('fup');
      form.setValue('fup', !fup);
      setAddFup(false);

      await submitForm();

      return;
    }

    // if (!isChartable(fupActive, day, correctedDay)) return;

    setShowExtraEntry(!showExtraEntry);
  }

  const outsideClickRef = useOnClickOutside(() => {
    if (showExtraEntry) submitForm();
  });

  return (
    <td
      key={day.id}
      onClick={onClick}
      ref={outsideClickRef}
    >
      <a className="chart-box extra d-flex justify-content-between align-items-center text-center text-secondary">
        <div className="ml-1">
          { notes
            ? (
              <i className="fa fa-pencil-square-o note-icon" />
            ) : null
          }
        </div>
        <div className="chart-day-extra-code-small">
          { abdominalPainId || pmsIds.length
            ? (
              <>
                { abdominalPainId ? <div>{ abdominalPain?.abbreviation }</div> : null }
                { pmsIds.length ? <div>PMS</div> : null }
              </>
            )
            : null
          }
        </div>
        <div className="mr-1 mt-1">
          { medicationIds?.length
            ? (
              <Prescription size={11} />
            ) : null
          }
        </div>
      </a>
      {showExtraEntry
        ?  <div className="extra-entry-popover position-absolute container">
            <ExtraEntry
              abdominalPains={abdominalPains}
              form={form}
              medications={medications}
              pmss={pmss}
              pushMedication={pushMedication}
              pushPms={pushPms}
              submitForm={submitForm}
            />
          </div>
        : null
      }
    </td>
  );
}
