export function captureClick(event) {
  event.preventDefault();
  event.stopPropagation();
}

export function scrollToCenter(element) {
  if (!element) return;

  const elementRect = element.getBoundingClientRect();

  const containerPosition = {
    x: elementRect.left + window.scrollX,
    y: elementRect.top + window.scrollY,
  };

  const centerPosition = {
    x: containerPosition.x + (elementRect.width / 2) - (window.innerWidth / 2),
    y: containerPosition.y + (elementRect.height / 2) - (window.innerHeight / 2),
  }

  window.scrollTo(centerPosition.x, centerPosition.y);
}

export function checkSession() {
  return fetch(
    '/app/ping',
    {
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      method: 'POST',
    },
  )
  .then((result) => {
    if (result.ok) return result;
    location.reload();
  })
  .then((result) => result.json())
  .then(({ data }) => data)
  .catch(() => location.reload());
}

export async function onSubmit(path, form_data, method = 'PUT') {
  try {
    const result = await fetch(
      path,
      {
        body: JSON.stringify(form_data),
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        method,
      },
    )

    // if (!result.ok) return location.reload();
    return result.json();
  } catch (error) {
    // location.reload()
  }
}

function arrayCompare(left, right) {
  if (left === right) return true;
  if (!left || !right) return false;

  if (left.length !== right.length) return false;

  return left.every((item, index) => item === right[index]);
}

export function isDirty(form, day) {
  const formDay = form.getValues();

  if (formDay.abdominal_pain_id !== day.abdominal_pain_id) return true;
  if (formDay.correction !== day.correction) return true;
  if (formDay.current_chart_day !== day.current_chart_day) return true;
  if (formDay.day_number !== day.day_number) return true;
  if (!arrayCompare(formDay.discharge_observation_ids, day.discharge_observation_ids)) return true;
  if (!arrayCompare(formDay.discharge_observation_modifier_ids, day.discharge_observation_modifier_ids)) return true;
  if (formDay.forgot_day !== day.forgot_day) return true;
  if (formDay.fup !== day.fup) return true;
  // if (formDay.id !== day.id) return true;
  if (formDay.intercourse_id !== day.intercourse_id) return true;
  if (!arrayCompare(formDay.medication_ids, day.medication_ids)) return true;
  if (!arrayCompare(formDay.menstrual_flow_ids, day.menstrual_flow_ids)) return true;
  if (formDay.notes !== day.notes) return true;
  if (formDay.observation_frequency_id !== day.observation_frequency_id) return true;
  if (formDay.original_day_id !== day.original_day_id) return true;
  if (!arrayCompare(formDay.pms_ids, day.pms_ids)) return true;
  if (formDay.point_of_change_answer_id !== day.point_of_change_answer_id) return true;
  if (formDay.recording_date !== day.recording_date) return true;
  if (formDay.same_as_yesterday_answer_id !== day.same_as_yesterday_answer_id) return true;
  if (formDay.sticker_id !== day.sticker_id) return true;
  return false;
}

export function handleDropdownPosition(placeholder, dropdown) {
  const screenPadding = -16;

  const placeholderRect = placeholder.getBoundingClientRect();
  const dropdownRect = dropdown.getBoundingClientRect();

  const dropdownRightX = dropdownRect.x + dropdownRect.width;
  const placeholderRightX = placeholderRect.x + placeholderRect.width;

  if (dropdownRect.x < 0) {
    dropdown.style.left = '0';
    dropdown.style.right = 'auto';
    dropdown.style.transform = `translateX(${-placeholderRect.x + screenPadding}px)`;
  } else if (dropdownRightX > window.outerWidth) {
    dropdown.style.left = 'auto';
    dropdown.style.right = '0';
    dropdown.style.transform = `translateX(${(window.outerWidth - placeholderRightX) - screenPadding}px)`;
  }
}

export function findCorrectedDay(currentDay, chartDays) {
  if (currentDay.correction) {
    const latestUncorrectedDay = chartDays
      .filter((chartDay) => !chartDay.correction)
      .filter((chartDay) => chartDay.original_day_id === currentDay.original_day_id || chartDay.id === currentDay.original_day_id)
      .reduce((mostRecentDay, chartDay) => {
        if (!mostRecentDay) return chartDay;
        return mostRecentDay.id > chartDay.id ? mostRecentDay : chartDay;
      }, null);

    if (latestUncorrectedDay) return latestUncorrectedDay;

    // const latestCorrectedDay = chartDays
    //   .filter((chartDay) => !chartDay.correction)
    //   .filter((chartDay) => chartDay.original_day_id === currentDay.original_day_id || chartDay.id === currentDay.original_day_id)
    //   .reduce((mostRecentDay, chartDay) => {
    //     if (!mostRecentDay) return chartDay;
    //     return mostRecentDay.id > chartDay.id ? mostRecentDay : chartDay;
    //   }, null);

    // if (latestCorrectedDay) return latestCorrectedDay;
  }

  return chartDays.find((chartDay) => chartDay.id === currentDay.id);
}

export function find_record(records, id) {
  return (records || []).find(record => record.id == id) || {};
}

export function find_diagram_side(codes, id) {
  return find_record(codes, id).diagram_side;
}

function containsCode(code, ids) {
  if (!ids) return false;

  return ids
    .map((id) => Number.parseInt(id))
    .includes(code.id);
}

export function showCode(code, currentIds, correctedIds) {
  return containsCode(code, currentIds) || containsCode(code, correctedIds);
}

export function correctionClass(fupActive, code, currentIds, dayIds, correctedIds) {
  // contained in the current selection (form)
  const current = containsCode(code, currentIds);
  // contained in the day on the server
  const day = containsCode(code, dayIds);
  // contained in the corrected day
  const corrected = containsCode(code, correctedIds);

  if (!fupActive && current && !day) return '';
  if (current && corrected) return '';
  if (current) return 'correction-added';
  if (corrected) return 'correction-removed';
  return '';
}

export function isPresent(record) {
  return Object.keys(record || {}).length > 0;
}

export function isChartable(fupActive, currentDay, correctedDay) {
  if (fupActive) return true;

  if (!currentDay.correction) return true;

  if (currentDay.abdominal_pain_id !== correctedDay.abdominal_pain_id) return false;
  if (!arrayCompare(currentDay.discharge_observation_ids, correctedDay.discharge_observation_ids)) return false;
  if (!arrayCompare(currentDay.discharge_observation_modifier_ids, correctedDay.discharge_observation_modifier_ids)) return false;
  if (currentDay.forgot_day !== correctedDay.forgot_day) return false;
  if (currentDay.intercourse_id !== correctedDay.intercourse_id) return false;
  if (!arrayCompare(currentDay.menstrual_flow_ids, correctedDay.menstrual_flow_ids)) return false;
  if (currentDay.observation_frequency_id !== correctedDay.observation_frequency_id) return false;
  if (currentDay.point_of_change_answer_id !== correctedDay.point_of_change_answer_id) return false;
  if (currentDay.recording_date !== correctedDay.recording_date) return false;
  if (currentDay.same_as_yesterday_answer_id !== correctedDay.same_as_yesterday_answer_id) return false;
  if (currentDay.sticker_id !== correctedDay.sticker_id) return false;
  return true;
}

export function parseDate(dateString) {
  const [year, month, date] = dateString.split('-')
  const parsedDate = new Date(year, month-1, date);
  return parsedDate;
};
