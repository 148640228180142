import React from 'react';
import Cycle from './Cycle';

export default function Body({
  addFup,
  chart,
  chartDays,
  dischargeObservations,
  dischargeObservationModifiers,
  essentialSameness,
  fupActive,
  keys,
  intercourses,
  medications,
  menstrualFlows,
  observationFrequencies,
  pdf,
  pmss,
  pointOfChangeAnswers,
  pushDay,
  pushMedication,
  pushPms,
  sameAsYesterdayAnswers,
  setAddFup,
  stickers,
  subscriptionActive,
}) {
  const cycles = [...chart.chart_cycles]
    .sort((left, right) => left.cycle_number - right.cycle_number);

  return (
    <tbody>
      {cycles.map((cycle) => (
        <Cycle
          abdominalPains={abdominalPains}
          key={cycle.id}
          addFup={addFup}
          chart={chart}
          chartDays={chartDays}
          cycle={cycle}
          dischargeObservations={dischargeObservations}
          dischargeObservationModifiers={dischargeObservationModifiers}
          essentialSameness={essentialSameness}
          fupActive={fupActive}
          keys={keys}
          intercourses={intercourses}
          medications={medications}
          menstrualFlows={menstrualFlows}
          observationFrequencies={observationFrequencies}
          pdf={pdf}
          pmss={pmss}
          pointOfChangeAnswers={pointOfChangeAnswers}
          pushDay={pushDay}
          pushMedication={pushMedication}
          pushPms={pushPms}
          sameAsYesterdayAnswers={sameAsYesterdayAnswers}
          setAddFup={setAddFup}
          stickers={stickers}
          subscriptionActive={subscriptionActive}
        />
      ))}
    </tbody>
  );
}

