import PropTypes from 'prop-types';
import React from 'react';
import { onSubmit } from '../ChartUtilities';

export default function Navbar({
  addFup,
  fupActive,
  setAddFup,
  scale,
  setScale,
}) {

  const endFup = async () => {
    const user = { fup_active: false };
    await onSubmit(`/app/profile`, { user });

    location.reload();
  }

  const onChangeScale = ({ target }) => {
    setScale(target.value);
  }

  return (
    <div className="navbar navbar-expand-lg navbar-light bg-light chart-header">
      { fupActive
        ? 
          <div className="navbar-nav">
            <span className="m-1 font-weight-bold border border-transparent">
              Follow-up in Progress
            </span>
            <a className="py-1 ml-2 border border-transparent" onClick={endFup}>
              End Fup
            </a>
            { addFup
              ? <a className="bg-transparent border border-primary ml-2 p-1" onClick={() => setAddFup(false)}>Cancel Add Fup Date</a>
              : <a className="bg-transparent border border-transparent ml-2 my-1" onClick={() => setAddFup(true)}>Add/Remove Fup Date </a>
            }
          </div>
        : null
      }
      <div className="mr-auto" />
      <div className="form-inline">
        <input
          className="form-control-range"
          type="range"
          onChange={onChangeScale}
          value={scale}
          min="0.2"
          max="2"
          step="0.2"
        />
      </div>
    </div>
  )
}

Navbar.propTypes = {
  addFup: PropTypes.bool.isRequired,
  fupActive: PropTypes.bool.isRequired,
  scale: PropTypes.string.isRequired,
  setAddFup: PropTypes.func.isRequired,
  setScale: PropTypes.func.isRequired,
};
