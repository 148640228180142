import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Navbar from './ChartDiagram/Navbar';
import Table from './ChartDiagram/Table';

const fallback = () => <div>Oops, an error has occured. Reloading page...</div>;

if (!window.pdf) {
  Bugsnag.start({
    apiKey: '4e8aaa200f7d796ebb58628bb08d7f54',
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginReact()],
    redactedKeys: [
      /^password$/,
      'first_name',
      'last_name',
      'phone_number',
      'birth_date',
      'User.email',
      'user.first_name',
      'user.last_name',
    ],
    releaseStage: process.env.NODE_ENV,
  });
}

const ApplicationErrorBoundary = window.pdf
  ? ErrorBoundary
  : Bugsnag.getPlugin('react').createErrorBoundary(React);

export default function ChartDiagram() {
  const [scale, setScale] = useState('1.0');

  const {
    abdominalPains,
    chart,
    dischargeObservations,
    dischargeObservationModifiers,
    essentialSameness,
    fupActive,
    keys,
    intercourses,
    medications,
    menstrualFlows,
    observationFrequencies,
    pdf,
    pmss,
    pointOfChangeAnswers,
    sameAsYesterdayAnswers,
    stickers,
    subscriptionActive,
  } = window;

  const [addFup, setAddFup] = useState(false);

  return (
    <ApplicationErrorBoundary FallbackComponent={fallback}>
      { !pdf
        ? (
          <Navbar
            addFup={addFup}
            fupActive={fupActive}
            scale={scale}
            setAddFup={setAddFup}
            setScale={setScale}
          />
        ) : null
      }
      <Table
        abdominalPains={abdominalPains}
        addFup={addFup}
        chart={chart}
        // chartDays={chartDays}
        dischargeObservations={dischargeObservations}
        dischargeObservationModifiers={dischargeObservationModifiers}
        essentialSameness={essentialSameness}
        fupActive={fupActive}
        keys={keys}
        intercourses={intercourses}
        medications={medications}
        menstrualFlows={menstrualFlows}
        observationFrequencies={observationFrequencies}
        pdf={pdf}
        pmss={pmss}
        pointOfChangeAnswers={pointOfChangeAnswers}
        sameAsYesterdayAnswers={sameAsYesterdayAnswers}
        scale={scale}
        setAddFup={setAddFup}
        stickers={stickers}
        subscriptionActive={subscriptionActive}
      />
      <div id="page-loaded" />
    </ApplicationErrorBoundary>
  );
}
